import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { configStore } from '@/stores/configStore'
import NavBarComp from '@/components/navigation/NavBarComp.vue'
import FooterComp from '@/components/navigation/FooterComp.vue'
import AuthView from '@/views/AuthView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'
import { i18n } from '@/modules/i18n'
import NotFoundView from '@/views/NotFoundView.vue'
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue'
import ResetPasswordView from '@/views/auth/ResetPasswordView.vue'
import { authStore } from '@/stores/authStore'

const routes = [
  {
    path: '/',
    name: 'home',
    components: {
      navBar: NavBarComp,
      default: HomeView,
      footer: FooterComp
    },
    meta: {
      auth: false,
      noAuth: false,
      title: 'home.title'
    }
  },
  {
    path: '/auth',
    name: 'auth',
    components: {
      navBar: NavBarComp,
      default: AuthView,
      footer: FooterComp
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginView,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.login.title'
        }
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterView,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.register.title'
        }
      },
      {
        path: 'forgot',
        name: 'forgotPassword',
        component: ForgotPasswordView,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.forgot.title'
        }
      },
      {
        path: 'reset/:token',
        name: 'resetPassword',
        component: ResetPasswordView,
        props: true,
        meta: {
          auth: false,
          noAuth: true,
          title: 'auth.reset.title'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    components: {
      navBar: NavBarComp,
      default: NotFoundView,
      footer: FooterComp
    },
    meta: {
      auth: false,
      noAuth: false,
      title: 'notFound.title'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()
  const { t, te } = i18n.global

  auth.checkJwt()

  config.menu = false
  config.languageMenu = false
  try {
    // @ts-ignore
    document.title = to.meta.title && te(to.meta.title as string)
      ? t(to.meta.title as string) + ' | ' + config.projectTitle
      : config.projectTitle
  } catch (unused) {
    document.title = config.projectTitle
  }

  if (to.meta.auth && !auth.isAuth) {
    next({ name: 'login' })
  } else if (to.meta.noAuth && auth.isAuth) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transition = toDepth < fromDepth ? 'slide-right' : (toDepth > fromDepth ? 'slide-left' : 'fade')
})

export default router

import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { configStore } from '@/stores/configStore'
import * as Vue from 'vue'
import './assets/css/style.css'
import { createPinia } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import axios from 'axios'
import { i18n } from '@/modules/i18n'

const pinia = createPinia()
pinia.use(({ store }) => { store.router = Vue.markRaw(router) })
pinia.use(PiniaSharedState({
  enable: true,
  initialize: true,
  type: 'localstorage'
}))
pinia.use(piniaPluginPersistedstate)

const vueProto = Vue.createApp(App)
  .use(pinia)
  .use(router)
  .use(i18n)

vueProto.config.warnHandler = () => null

const config = configStore()

axios.defaults.baseURL = config.projectURL
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.timeout = 20000

// @ts-ignore
i18n.global.locale = config.currentLanguage.code
router.isReady().then(() => {
  vueProto.mount('#app')
})

import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'
import { loginUser, type user, userLogin, userRegister } from '@/interfaces/auth'
import { get, post, put } from '@/modules/asyncData'
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { arrayBufferToBase64, base64ToArrayBuffer } from '@/modules/helpers'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import { JWT } from '@/interfaces/basic'

export const authStore = defineStore('auth', () => {
  const isAuth = ref<boolean>(false)
  const jwtToken = ref<string>('')
  const user: user = reactive<user>({
    emailStatus: '',
    firstName: '',
    lastName: '',
    id: '',
    email: ''
  })
  const expires = ref<number>(0)

  const refreshValue = moment.duration(2, 'hours').asSeconds()

  const setJwt = (token: string) => {
    jwtToken.value = token
    // axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
    Object.assign(user, jwtDecode<user>(token))
    isAuth.value = true
  }

  const checkJwt = () => {
    if (expires.value > 0 && expires.value < moment().unix()) {
      jwtToken.value = ''
      expires.value = 0
      isAuth.value = false
    } else if (expires.value > 0 && expires.value + refreshValue < moment().unix()) {
      get<loginUser>('auth.refresh')
        .then(r => {
          jwtToken.value = r.data.token
          // axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
          Object.assign(user, jwtDecode<user>(r.data.token))
          isAuth.value = true
        })
        .catch(() => {
          logout()
          const route = useRoute()
          const router = useRouter()
          if (route.meta.auth) router.push({ name: 'login' })
        })
    }
  }

  const login = async (user: userLogin): Promise<void> => {
    return new Promise((resolve, reject) => {
      post<loginUser>('auth/login', user)
        .then(r => {
          jwtToken.value = r.data.token
          // axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
          Object.assign(user, jwtDecode<user>(r.data.token))
          isAuth.value = true
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const logout = (): void => {
    isAuth.value = false
    Object.assign(user, {
      emailStatus: '',
      firstName: '',
      lastName: '',
      id: '',
      email: ''
    })
    jwtToken.value = ''
    axios.defaults.headers.common.Authorization = undefined
  }

  const register = async (user: userRegister): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      put<null>('auth/register', user)
        .then(r => {
          console.log(r)
          isAuth.value = false
          resolve()
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }

  const forgotPassword = async (userName: string): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      put<null>('auth/forgot', { userName })
        .then(() => {
          resolve()
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  const resetPassword = async (password: string, token: string): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      post<loginUser>('auth/reset', { password, token })
        .then((r) => {
          jwtToken.value = r.data.token
          // axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
          Object.assign(user, jwtDecode<JWT>(r.data.token).sub)
          expires.value = jwtDecode<JWT>(r.data.token).exp
          isAuth.value = true
          resolve()
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }

  const fidoRegister = async (token?: string): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      // get<any>('https://proto.reavo.local:13020/index.php')
      get<any>('auth/fido/' + token)
        .then(response => {
          const data = response.data
          data.publicKey.user.id = base64ToArrayBuffer(data.publicKey.user.id)
          data.publicKey.challenge = base64ToArrayBuffer(data.publicKey.challenge)
          return data
        })
        .then(async createCredentialArgs => {
          return await navigator.credentials.create(createCredentialArgs)
        })
        .then(async (credentials: Credential | null) => {
          // @ts-ignore
          if (credentials === null) throw new Error('nope')
          await put<loginUser>('auth/fido', {
            id: credentials.id,
            // @ts-ignore
            clientDataJSON: arrayBufferToBase64(credentials.response.clientDataJSON),
            // clientDataJSON: String.fromCharCode.apply(null, new Uint8Array(credentials.response.clientDataJSON)),
            // @ts-ignore
            attestationObject: arrayBufferToBase64(credentials.response.attestationObject),
            token
          })
            .then(r => {
              console.log(r)
              jwtToken.value = r.data.token
              // axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
              Object.assign(user, jwtDecode<JWT>(r.data.token).sub)
              expires.value = jwtDecode<JWT>(r.data.token).exp
              isAuth.value = true
              resolve()
            })
        })
        .catch(e => {
          console.log(e)
          reject(e)
        })
    })
  }

  const fidoLogin = async (): Promise<unknown> => {
    return new Promise<void>((resolve, reject) => {
      try {
        get<any>('auth/fido-challenge')
          .then(response => {
            const request = response.data
            request.publicKey.challenge = base64ToArrayBuffer(request.publicKey.challenge)
            return request
          })
          .then(async challenge => {
            return {
              challenge: await navigator.credentials.get(challenge),
              jwt: challenge.jwt
            }
          })
          .then(async result => {
            if (result.challenge === null) throw new Error('nope')
            // @ts-ignore
            console.log(result, arrayBufferToBase64(result.challenge.response.userHandle), result.challenge.response.userHandle)
            await post<loginUser>('auth/fido', {
              id: result.challenge.id,
              response: {
                // @ts-ignore
                clientDataJSON: arrayBufferToBase64(result.challenge.response.clientDataJSON),
                // @ts-ignore
                authenticatorData: arrayBufferToBase64(result.challenge.response.authenticatorData),
                // @ts-ignore
                signature: arrayBufferToBase64(result.challenge.response.signature),
                // @ts-ignore
                userHandle: arrayBufferToBase64(result.challenge.response.userHandle)
              },
              jwt: result.jwt
            })
              .then(r => {
                console.log(r)
                jwtToken.value = r.data.token
                // axios.defaults.headers.common.Authorization = `Bearer ${jwtToken.value}`
                Object.assign(user, jwtDecode<JWT>(r.data.token).sub)
                expires.value = jwtDecode<JWT>(r.data.token).exp
                isAuth.value = true
                resolve()
              })
          })
          .catch(e => {
            reject(e)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  const googleLogin = (): void => {
    const u = new URLSearchParams({
      scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      response_type: 'token',
      state: Math.random() * 100000 + '',
      redirect_uri: 'https://proto.reavo.dev/api/auth/google',
      client_id: '262676152355-si5p20ukj8gsug8di8gqqves4isqrt3q.apps.googleusercontent.com'
    }).toString()
    location.href = 'https://accounts.google.com/o/oauth2/v2/auth?' + u
  }

  return { isAuth, user, jwtToken, checkJwt, login, logout, register, forgotPassword, resetPassword, fidoRegister, fidoLogin, googleLogin, setJwt }
},
{
  persist: {
    paths: ['isAuth', 'jwtToken', 'user']
  }
}
)

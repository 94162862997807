import * as buffer from 'buffer'
export function base64ToArrayBuffer (str: string): any {
  const binaryString = window.atob(str)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  // @ts-ignore
  return bytes.buffer
}

/**
 * convert RFC 1342-like base64 strings to array buffer
 * @param {mixed} obj
 * @returns {undefined}
 */
export function recursiveBase64StrToArrayBuffer (obj: object): void {
  const prefix = '=?BINARY?B?'
  const suffix = '?='
  if (typeof obj === 'object') {
    for (const key in obj) {
      if (typeof obj[key as keyof object] === 'string') {
        let str: string = obj[key as keyof object]
        if (str.substring(0, prefix.length) === prefix && str.substring(str.length - suffix.length) === suffix) {
          str = str.substring(prefix.length, str.length - suffix.length)

          const binaryString = window.atob(str)
          const len = binaryString.length
          const bytes = new Uint8Array(len)
          for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i)
          }
          // @ts-ignore
          obj[key as keyof object] = bytes.buffer
        }
      } else {
        recursiveBase64StrToArrayBuffer(obj[key as keyof object])
      }
    }
  }
}

/**
 * Convert a ArrayBuffer to Base64
 * @param {ArrayBuffer} buffer
 * @returns {String}
 */
export function arrayBufferToBase64 (buffer: Buffer): string {
  let binary = ''
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

<script setup lang="ts">

</script>

<template>
  <div class="p-4">Footer</div>
</template>

<style scoped lang="scss">

</style>

import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import { i18n } from '@/modules/i18n'
import { useRoute } from 'vue-router'
import { Language } from '@/interfaces/config'

export const configStore = defineStore('config', () => {
  const projectURL = process.env.VUE_APP_DOMAIN ? `${process.env.VUE_APP_DOMAIN}/api/` : 'https://proto.reavo.dev/api/'
  const projectTitle = 'reavo.io'
  const route = useRoute()

  const menu = ref<boolean>(false)

  // ------------- LANGUAGE ---------------------

  const currentLanguage = reactive<Language>({ code: 'de', flag: 'de' })
  const languageMenu = ref<boolean>(false)
  const languages = ref<Language[]>([{ code: 'de', flag: 'de' }, { code: 'en', flag: 'gb' }, { code: 'es', flag: 'es' }])

  watch(currentLanguage, (value) => {
    i18n.global.locale = value.code
    languageMenu.value = false
    document.title = i18n.global.t(route?.meta?.title as string ?? '')
  })

  // ------------- DARKMODE ---------------------

  const systemDarkMode = ref<boolean>(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
  const darkModeSettings = ref<'light' | 'dark' | 'system'>('system')
  const darkModeMenu = ref<boolean>(false)

  const darkMode = computed<'light' | 'dark'>(() => {
    const temp = darkModeSettings.value === 'system' ? (systemDarkMode.value ? 'dark' : 'light') : darkModeSettings.value
    document.getElementsByTagName('html')[0].className = temp
    return temp
  })

  if (window.matchMedia) {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      systemDarkMode.value = event.matches
    })
  }

  watch(darkModeSettings, async (newValue) => {
    document.getElementsByTagName('html')[0].className = newValue === 'system' ? (systemDarkMode.value ? 'dark' : 'light') : newValue
  })

  document.getElementsByTagName('html')[0].className = darkModeSettings.value === 'system' ? (systemDarkMode.value ? 'dark' : 'light') : darkModeSettings.value

  // -----------

  return { projectURL, projectTitle, currentLanguage, languageMenu, languages, menu, darkMode, darkModeMenu, darkModeSettings }
},
{
  persist: {
    paths: ['currentLanguage', 'darkModeSettings']
  }
}
)

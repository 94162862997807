<script setup lang="ts">

</script>

<template>
  <div>{{ $t('language') }}</div>
</template>

<style scoped lang="scss">

</style>
